//TODO: Tornar necessário apenas a passagem do ID do wrapper do formulário, e obter os demais elementos no escopo da própria função 
export default function( button_submit_id, wrapper_lead_form_id, lead_form_id  ) {

    $( button_submit_id ).on("click", function ( ) {

        const section_id = wrapper_lead_form_id;
        const formSelector = section_id + " form";
    
        //se o formulário não estiver válido
        if( !$( formSelector ).valid() ) return;
    
        const data = {
    
            conversion_identifier:                  $(section_id + " form").attr("id"),
            name:                                   $(section_id).find("input[name='nome']").val(),
            mobile_phone:                           $(section_id).find("input[name='telefone']").val(),
            email:                                  $(section_id).find("input[name='email']").val(),
            cf_qual_e_a_mensalidade_da_sua_escola:  $(section_id).find("select[name='mensalidade']").val(),
            cf_cargo_do_lead:                       $(section_id).find("select[name='cargo']").val(),
            cf_nome_da_escola:                      $(section_id).find("input[name='escola-inep-free']").val(),
            cf_perfil_da_escola:                    $(section_id).find("select[name='escola-perfil']").val(),
            cf_quantos_alunos_tem_a_sua_escola:     $(section_id).find("select[name='alunos-qtd']").val(),
    
        };
    
        $.ajax({
    
            url: "/wp-json/edify-marketing/conversion",
            method: "POST", 
            data: data,
            success: function() {
                $( formSelector ).trigger("submit");
            }
    
        });
    
        dataLayer.push({event: "gtm.formSubmit"});
    
        window.location.href = $( lead_form_id ).data("redirect");
    
    
    })

}